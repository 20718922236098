import axios from 'axios';
import { navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../components/Button';
import Card from '../components/Card';
import Layout from '../components/Layout';
import OverlayBox from '../components/OverlayBox';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../context/GlobalContextProvider';

const StyledMyAccount = styled.div`
  .single-customer-inner {
    display: flex;
    .single-customer-left-content {
      width: 60%;
      margin-right: 1.5rem;
    }
    .single-customer-right-content {
      width: calc(40% - 1.5rem);
    }
  }
  form {
    p {
      margin-bottom: 0.5rem !important;
    }
  }
  .avatar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .avatar-image-container {
      width: 300px;
      height: 300px;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 6px 6px 8px rgba(0, 0, 0, 0.1);
      margin-bottom: 1rem;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        &.error {
          object-fit: contain;
        }
      }
    }
  }
  #avatarFileUpload {
    display: none;
  }

  .confirm-overlay-box {
    button {
      margin-right: 1rem;
    }
  }
  @media (max-width: 1000px) {
    .single-customer-inner {
      flex-direction: column;
      .single-customer-left-content {
        width: 100%;
      }
      .single-customer-right-content {
        width: 100%;
      }
    }
  }
  .confirm-overlay-box {
    button {
      margin-right: 0.5rem;
    }
    .customer-card-header {
      button {
        margin-right: 0;
      }
    }
  }
`;

const MyAccount = () => {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const [userInfo, setUserInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
  });

  const [changePasswordFormData, setChangePasswordFormData] = useState({
    oldPassword: '',
    newPassword: '',
    newPasswordRepeat: '',
  });

  const [confirmChange, setConfirmChange] = useState({
    active: false,
    proceed: () => {},
  });

  const onChangeUserInfo = (e) => {
    setUserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  const updateUserInfo = (e) => {
    // e.preventDefault()
    dispatch({ type: 'LOADING', value: true });
    const cleanedFormData = {
      firstName: userInfo.firstName.trim(),
      lastName: userInfo.lastName.trim(),
      email: userInfo.email.trim(),
      phone: userInfo.phone,
    };
    axios
      .post(
        `${process.env.GATSBY_BACKEND_API_URL}users/auth/self-update/${state.user._id}`,
        { user: cleanedFormData },
        {
          headers: { Authorization: 'Bearer ' + state.token },
        }
      )
      .then(() => {
        dispatch({ type: 'ALERT', content: 'Användare sparad' });
        localStorage.removeItem('token');
        dispatch({ type: 'LOGOUT' });
        setTimeout(() => {
          navigate('/');
        }, 500);
      })
      .catch((err) => {
        dispatch({ type: 'LOADING', value: false });
        //console.log(err.response)
        if (err.response.data === 'Email exists') {
          dispatch({ type: 'ALERT', content: 'Mailadressen finns redan' });
        } else {
          dispatch({ type: 'ALERT', content: 'Något gick fel' });
        }
      });
  };

  const loadUser = () => {
    setUserInfo({
      firstName: state.user.firstName,
      lastName: state.user.lastName,
      email: state.user.email,
      phone: state.user.phone || '',
    });
    dispatch({ type: 'LOADING', value: false });
  };

  const onChangePassword = (e) => {
    setChangePasswordFormData({
      ...changePasswordFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangePassword = (e) => {
    // e.preventDefault()
    dispatch({ type: 'LOADING', value: true });
    if (
      changePasswordFormData.newPassword !==
      changePasswordFormData.newPasswordRepeat
    ) {
      dispatch({ type: 'ALERT', content: 'Lösenorden matchar inte' });
      dispatch({ type: 'LOADING', value: false });
      return;
    }
    const body = {
      user: {
        oldPassword: changePasswordFormData.oldPassword,
        newPassword: changePasswordFormData.newPassword,
      },
    };
    axios
      .post(
        `${process.env.GATSBY_BACKEND_API_URL}users/auth/change-password/${state.user._id}`,
        body,
        {
          headers: { Authorization: 'Bearer ' + state.token },
        }
      )
      .then(() => {
        dispatch({ type: 'ALERT', content: 'Lösenord ändrat' });
        setChangePasswordFormData({
          oldPassword: '',
          newPassword: '',
          newPasswordRepeat: '',
        });
        localStorage.removeItem('token');
        dispatch({ type: 'LOGOUT' });
        setTimeout(() => {
          navigate('/');
        }, 500);
      })
      .catch((err) => {
        //console.log(err.response)
        dispatch({ type: 'LOADING', value: false });
        if (err.response.data.details) {
          if (err.response.data.details[0].path[0] === 'newPassword') {
            dispatch({
              type: 'ALERT',
              content:
                'Lösenordet måste vara minst 10 tecken långt och innehålla både stora och små bokstäver, samt minst en siffra',
            });
          } else {
            dispatch({ type: 'ALERT', content: 'Något gick fel' });
          }
        } else if (err.response.status === 401) {
          dispatch({ type: 'ALERT', content: 'Fel lösenord' });
        } else {
          dispatch({ type: 'ALERT', content: 'Något gick fel' });
        }
      });
  };

  // const handleAvatarUpload = e => {
  //   const fileInput = document.getElementById("avatarFileUpload")
  //   if (e.target.files.length === 0) return
  //   dispatch({ type: "LOADING_PROGRESS", progress: 1, value: true })
  //   uploadFiles(e.target.files)
  //   fileInput.value = null
  // }

  // const uploadFiles = currentFiles => {
  //   const formData = new FormData()
  //   formData.append("avatar", currentFiles[0])
  //   axios
  //     .post(`${do_url}avatars/${state.token}/${state.user._id}`, formData, {
  //       headers: { "content-type": "multipart/form-data" },
  //       onUploadProgress: progressEvent => {
  //         const progress = Math.round(
  //           (progressEvent.loaded * 100) / progressEvent.total
  //         )
  //         dispatch({
  //           type: "LOADING_PROGRESS",
  //           progress: progress,
  //           value: true,
  //         })
  //       },
  //     })
  //     .then(() => {
  //       dispatch({ type: "ALERT", content: "Filen har laddats upp" })
  //       setTimeout(() => {
  //         if (typeof window !== `undefined`) {
  //           window.location.reload()
  //         }
  //       }, 1000)
  //     })
  //     .catch(err => {
  //       dispatch({ type: "ALERT", content: "Något gick fel" })
  //       dispatch({ type: "LOADING_PROGRESS", progress: null, value: false })
  //       //console.log(err)
  //     })
  // }

  useEffect(() => {
    dispatch({ type: 'LOADING', value: true });

    if (state.token.length > 0) {
      loadUser();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.token, state.user]);

  return (
    <Layout>
      <StyledMyAccount>
        <h1 className="page-header">Mitt konto</h1>
        <div className="single-customer-inner">
          <div className="single-customer-left-content">
            <Card>
              <div className="customer-card-header">
                <h3>Ändra uppgifter</h3>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setConfirmChange({
                    active: true,
                    proceed: () => {
                      updateUserInfo(e);
                    },
                  });
                }}
              >
                <input
                  required
                  type="text"
                  name="firstName"
                  value={userInfo.firstName}
                  onChange={onChangeUserInfo}
                  placeholder="Förnamn"
                />
                <input
                  required
                  type="text"
                  name="lastName"
                  value={userInfo.lastName}
                  onChange={onChangeUserInfo}
                  placeholder="Efternamn"
                />
                <input
                  required
                  type="text"
                  name="email"
                  value={userInfo.email}
                  onChange={onChangeUserInfo}
                  placeholder="E-post"
                />
                <input
                  type="text"
                  name="phone"
                  value={userInfo.phone}
                  onChange={onChangeUserInfo}
                  placeholder="Telefonnummer"
                />
                <p>
                  <strong>Roll:</strong> {state.user.role}
                </p>
                <Button med type="submit">
                  Spara
                </Button>
              </form>
            </Card>
            <Card>
              <div className="customer-card-header">
                <h3>Byt lösenord</h3>
              </div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  setConfirmChange({
                    active: true,
                    proceed: () => {
                      handleChangePassword(e);
                    },
                  });
                }}
              >
                <input
                  required
                  type="password"
                  name="oldPassword"
                  value={changePasswordFormData.oldPassword}
                  onChange={onChangePassword}
                  placeholder="Nuvarande lösenord"
                />
                <input
                  required
                  type="password"
                  name="newPassword"
                  value={changePasswordFormData.newPassword}
                  onChange={onChangePassword}
                  placeholder="Nytt lösenord"
                />
                <input
                  required
                  type="password"
                  name="newPasswordRepeat"
                  value={changePasswordFormData.newPasswordRepeat}
                  onChange={onChangePassword}
                  placeholder="Upprepa nytt lösenord"
                />
                <Button med type="submit">
                  Spara
                </Button>
              </form>
            </Card>
          </div>
          <div className="single-customer-right-content">
            {/* <Card>
              <div className="customer-card-header">
                <h3>Visningsbild</h3>
              </div>
              <div className="avatar-container">
                <div className="avatar-image-container">
                  <img
                    src={`${do_url}avatars/${state.token}/${state.user._id}`}
                    alt=""
                    onError={e => {
                      e.target.src = "/images/logo.png"
                      e.target.classList.add("error")
                    }}
                  />
                </div>
                <input
                  id="avatarFileUpload"
                  type="file"
                  accept=".png,.jpg,.jpeg"
                  onChange={handleAvatarUpload}
                />
                <Button
                  med
                  onClick={() => {
                    document.getElementById("avatarFileUpload").click()
                  }}
                >
                  Ändra bild
                </Button>
              </div>
            </Card> */}
          </div>
        </div>
        <OverlayBox
          className="confirm-overlay-box"
          active={confirmChange.active}
          header="Är du säker?"
          close={() =>
            setConfirmChange({
              active: false,
              proceed: () => {},
            })
          }
        >
          <p>
            När du sparar en ändring av din profilinformation så loggas du ut.
            <br />
            Vill du fortsätta?
          </p>
          <Button med onClick={confirmChange.proceed}>
            Fortsätt
          </Button>
          <Button
            med
            onClick={() =>
              setConfirmChange({
                active: false,
                proceed: () => {},
              })
            }
          >
            Tillbaka
          </Button>
        </OverlayBox>
      </StyledMyAccount>
    </Layout>
  );
};

export default MyAccount;
